
  
  import React, { useState, useRef, useEffect } from "react";
  import { useNavigate } from "react-router-dom";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/style.css";
  import styles from "../Styles/PhoneAuth.module.css";
  import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
  
  const PhoneAuth = ({ getOtp, verifyOtp, loading, det1, det }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const otpRefs = useRef([]);
    const navigate = useNavigate();
    const prevOtp = useRef([...otp]);
    const [selectedCountry, setSelectedCountry] = useState("bd");
    const [selectedCountryCode, setSelectedCountryCode] = useState("+880");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(0);
    const [otpError, setOtpError] = useState("");

    const handleChange = (e, index) => {
      const { value } = e.target;
      if (/^[0-9]*$/.test(value)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
  
        if (value && index < otpRefs.current.length - 1) {
          otpRefs.current[index + 1].focus();
        }
      }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace" && !otp[index] && index > 0) {
        otpRefs.current[index - 1].focus();
      } else if (e.key === "ArrowLeft" && index > 0) {
        otpRefs.current[index - 1].focus();
      } else if (e.key === "ArrowRight" && index < otp.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    };
  
    const handleInput = (e, index) => {
      const { value } = e.target;
  
      if (!value && prevOtp.current[index] && index > 0) {
        otpRefs.current[index - 1].focus();
      }
  
      prevOtp.current = [...otp];
    };
  
    const handlePhoneNumberChange = (e) => {
      const phoneValue = e.target.value.replace(/\D/g, "");
      setPhoneNumber(phoneValue);
    };
  
    const handleGetOtp = async () => {
      if (phoneNumber.length < 10) {
        setOtpError("Enter a valid phone number");
        return;
      }
  
      setOtpError("Loading...");
      const success = await getOtp(`${selectedCountryCode}${phoneNumber}`);
      if (success) {
        const currentTime = Date.now();
        localStorage.setItem("otpTimestamp", currentTime);
        setIsButtonDisabled(true);
        setOtpError("");
        setTimer(60);
  
        const intervalId = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer === 1) {
              clearInterval(intervalId);
              setIsButtonDisabled(false);
              localStorage.removeItem("otpTimestamp");
            }
            return prevTimer - 1;
          });
        }, 1000);
      } else {
        setOtpError("Failed to send OTP");
      }
    };
    const handleVerifyOtp = async () => {
      const otpString = otp.join("");
      try {
        await verifyOtp(selectedCountryCode+phoneNumber, otpString);
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    };
    useEffect(() => {
      const savedTimestamp = localStorage.getItem("otpTimestamp");
      if (savedTimestamp) {
        const currentTime = Date.now();
        const elapsedTime = Math.floor((currentTime - savedTimestamp) / 1000);
        if (elapsedTime < 60) {
          setTimer(60 - elapsedTime);
          setIsButtonDisabled(true);
          const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
              if (prevTimer === 1) {
                clearInterval(intervalId);
                setIsButtonDisabled(false);
                localStorage.removeItem("otpTimestamp");
              }
              return prevTimer - 1;
            });
          }, 1000);
        }
      }
    }, []);
  
    useEffect(() => {
      if (det === "Wrong Otp") {
        setOtpError("Invalid OTP, please try again.");
      } else if (det === "success") {
        navigate("/booking", { state: { selectedCountry } });
      }
    }, [det, navigate, selectedCountry]);
  
    return (
      <div className={styles.phoneAuth}>
        <div className={styles.inner_div}>
          <img
            src="logo2.png"
            alt="Logo"
            className={styles.logo}
            style={{
              width: "8rem",
              paddingTop: "10px",
              filter: "drop-shadow(0 0 5px black)",
            }}
          />
          <h4 style={{ fontWeight: "lighter" }}>
            Please Continue With Your Phone Number
          </h4>
          <form className={styles.authForm}>
            <div className={styles.formGroup}>
              <label
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  marginLeft: "10px",
                  color: "black",
                }}
              >
                Phone Number:
              </label>
              <div className={styles.inputGroup} style={{ position: "relative" }}>
                {/* Read-only PhoneInput for country code */}
                <PhoneInput
                  country={selectedCountry}
                  value=""
                  enableSearch={true}
                  onChange={(value, country) => {
                    setSelectedCountryCode(country.dialCode);
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    paddingLeft: "48px",
                    pointerEvents: "none",
                    backgroundColor: "#f8f8f8",
                    boxShadow: "none", // Removes focus shadow
                  }}
                  buttonStyle={{
                    borderRadius: "4px 0 0 4px",
                    border: "1px solid #ccc",
                  }}
                  dropdownStyle={{
                    borderRadius: "0 0 4px 4px",
                    background: "#343434",
                    textAlign: "left",
                    color: "#666666",
                  }}
                  searchStyle={{ background: "#343434" }}
                />
  
                {/* Editable phone number input */}
                <input
                  type="tel"
                  className={styles.editablePhoneInput}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "77px",
                    height: "37px",
                    width: "calc(100% - 110px)",
                    border: "none",
                    paddingLeft: "10px",
                    boxShadow: "none",
                    background: "rgba(0,0,0,0)",
                  }}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Enter Rest of Your phone number"
                />
                <FaPhoneAlt className={styles.icon} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                type="button"
                className={styles.getOtpButton}
                onClick={handleGetOtp}
                disabled={isButtonDisabled}
              >
                Get OTP{" "}
                <FaEnvelope style={{ marginLeft: "10px", marginRight: "5px" }} />
              </button>
              {isButtonDisabled && (
                <span style={{ marginLeft: "10px", color: "red" }}>{timer}s</span>
              )}
              <p
                style={{
                  margin: "0",
                  alignContent: "center",
                  color: "red",
                  marginLeft: "10px",
                }}
              >
                {otpError || det1}
              </p>
            </div>
            <div className={styles.otpGroup}>
              <label>ENTER OTP</label>
              <div className={styles.otpInputs}>
                {otp.map((digit, idx) => (
                  <input
                    className={styles.inputName}
                    key={idx}
                    type="tel"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e, idx)}
                    onKeyDown={(e) => handleKeyDown(e, idx)}
                    onInput={(e) => handleInput(e, idx)}
                    ref={(el) => (otpRefs.current[idx] = el)}
                  />
                ))}
              </div>
            </div>
            <div style={{ textAlign: "center", padding: "0px 20px 20px 20px" }}>
              <button
                type="button"
                className={styles.continueButton}
                onClick={handleVerifyOtp}
              >
                {loading ? "loading..." : "Continue"}{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/arrow.svg`}
                  className={styles.arrowIcon}
                  alt="arrow"
                />
              </button>
            </div>
            <p style={{ color: "red", width: "100%" }}>{det}</p>
          </form>
        </div>
      </div>
    );
  };
  
  export default PhoneAuth;
  